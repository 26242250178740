import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO />
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="404."
      children="This page doesn&#39;t exist..."
      styleClass="default-banner"
    />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "home-banner.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 100
          duotone: { highlight: "#071730", shadow: "#071730", opacity: 1 }
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default NotFoundPage
